/* You can add global styles to this file, and also import other style files */
.edit-recipe span.dropdown-btn {
  border-radius: 15px 15px 15px 15px !important;
  border: 1px solid #E3E3E3 !important;
}

.confirm-button {
  background-color: #B2D571 !important;
  border-radius: 30px;
}

.cancel-button {
  background-color: #F36F48 !important;
  border-radius: 30px;
}

.color-picker .saturation-lightness {
  display: none !important;
}

.color-picker .hue-alpha {
  display: none !important;
}

.color-picker .hex-text {
  display: none !important;
}

.color-picker .type-policy {
  display: none !important;
}

/* .main-panel {
  height: inherit !important;
  max-height: inherit !important;
} */

.multiselect-dropdown .dropdown-list {
  overflow: hidden;
}

#ngb-tooltip-0 {
  left: -80px !important;
}

#ngb-tooltip-0 .arrow {

  left: auto !important;
  right: 10px;

}

.required-field {
  position: relative;
}

.required-field::after {
  content: "*";
  color: red;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .predefined-tag {
    margin-bottom: -15px;
  }
}