.wrapper{
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 260px;
    left: 0;
    z-index: 1030;

    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 75px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;

        .dropdown .dropdown-backdrop{
          display: none !important;
        }

        .navbar-form{
            border: none;
        }
    }

    .logo-img{
      width: 35px;
      height: 35px;
      display: block;
      margin-left: 2px;
      position: absolute;
      top: 12px;
        img{
            width: 35px;
        }
    }

    .navbar-minimize{
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1;

      @extend .animation-transition-general;
    }
    .logo-tim{
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img{
          width: 60px;
          height: 60px;
      }
    }

    .nav{
        margin-top: 20px;
        display: block;

        .caret{
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li{
            > a + div .nav li > a{
                margin-top: 7px;
            }

            > a{
                margin: 10px 15px 0;
                margin-left: 0px;
                border-radius: 0 30px 30px 0;
                color: black;
                display: block;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: $font-size-mini;
                padding: 10px 8px;
                line-height: $line-height-nav-link;
            }

            &:first-child > a{
                margin: 0 15px;
                margin-left: 0px;
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a{
                background-color: $opacity-1;

                i{
                  color: $white-color;
                }
            }

            &.active > a,
            &.active-pro > a{
              background-color: #b2d571 !important;
              box-shadow: $box-shadow;
              i{
                color: $danger-color;
              }
            }
            :hover &.active-pro > a,
            :focus &.active-pro > a{
              background-color: $white-color;
              box-shadow: $box-shadow;
              color: $danger-color;
              i{
                color: $danger-color;
              }
            }
        }

        p{
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
        }

        i{
            font-size: 20px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $opacity-5;
            position: relative;
        }
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #b2d571 !important;
            opacity: 1;
        }
    }

    .logo{
        position: relative;
        padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }

        a.logo-normal{
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $white-color;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after{
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:after{
        @include icon-gradient($default-color);
        z-index: 3;
    }

    &[data-color="blue"]{
      @include sidebar-color($info-color);
    }
    &[data-color="green"]{
      @include sidebar-color($success-color);
    }
    &[data-color="orange"]{
      @include sidebar-color($orange-color);
    }
    &[data-color="red"]{
      @include sidebar-color($danger-color);
    }
    &[data-color="yellow"]{
      @include sidebar-color($warning-color);
    }
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    position: relative;
    float: right;
    width: $sidebar-width;
    // width: 100%;
    background-color: $light-gray;
    background-color: #ebecf1;

    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content{
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px;
    }

    .navbar{
        margin-bottom: 0;
        .container-fluid{
            padding-right: 15px;
            padding-left: 15px;
        }
        .navbar-toggler-bar{
            display: block;
            position: relative;
            width: 22px;
            height: 1px;
            border-radius: 1px;
            background: $white-bg;

            & + .navbar-toggler-bar{
                margin-top: 7px;
            }

            & + .navbar-toggler-bar.navbar-kebab{
                margin-top: 3px;
            }

            &.bar2{
                width: 17px;
                transition: width .2s linear;
            }
      }
          .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }
    .bar1 {
      top: 0px;
      @include bar-animation($topbar-back);
    }
    .bar2 {
      opacity: 1;
    }
    .bar3 {
      bottom: 0px;
      @include bar-animation($bottombar-back);
    }
    .toggled .bar1 {
      top: 6px;
      @include bar-animation($topbar-x);
    }
    .toggled .bar2 {
      opacity: 0;
    }
    .toggled .bar3 {
      bottom: 6px;
      @include bar-animation($bottombar-x);
    }
    .navbar-collapse{
          .input-group{
            margin: 0;
            margin-top: 5px;
          }
        }
         .navbar-nav{
            .nav-item:first-child{
              margin-top: 10px;
            }
            .nav-item:not(:last-child){
                margin-bottom: 10px;
            }
        }

        .dropdown.show .dropdown-menu{
            display: block;
        }

        .dropdown .dropdown-menu{
            display: none;
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu{
            background-color: transparent;
            border: 0;
            transition: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            width: auto;
            margin: 0px 1rem;
            margin-top: 0px;

            &:before{
                display: none;
            }
        }

        .dropdown-menu .dropdown-item:focus,
        .dropdown-menu .dropdown-item:hover{
            color: $white-color;
        }

        &.bg-white .dropdown-menu .dropdown-item:focus,
        &.bg-white .dropdown-menu .dropdown-item:hover{
            color: $default-color;
        }

        &.bg-white:not(.navbar-transparent) .navbar-toggler-bar{
            background-color: $default-color;
        }

        & .toggled .navbar-toggler-bar{
            width: 24px;

            & + .navbar-toggler-bar{
                margin-top: 5px;
            }
        }
    }


    .header{
        margin-bottom: 50px;
    }
}

@include topbar-x-rotation();
@include topbar-back-rotation();
@include bottombar-x-rotation();
@include bottombar-back-rotation();



.nav-open{
        .main-panel{
            width: calc(100% - 260px);
            right: 0;
        }

        .sidebar{
            @include transform-translate-x(0px);
        }

        body{
            position: relative;
            overflow-x: hidden;
        }

        .menu-on-right{
            .navbar-collapse,
            .sidebar{
                @include transform-translate-x(0px);
            }

            .navbar-translate{
                @include transform-translate-x(-300px);
            }

            #bodyClick{
                right: 260px;
                left: auto;
            }
        }
    }

    .sidebar,
    .bootstrap-navbar {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 260px;
        right: auto;
        left: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

        @include transform-translate-x(-260px);
    }

    .main-panel{
        width: 100%;
    }



.perfect-scrollbar-on{
  .sidebar,
  .main-panel{
      height: 100%;
      max-height: 100%;
  }
}


@media (min-width: 991px) {
  .sidebar{
    display: block;
    box-shadow: $sidebar-box-shadow;
  }
}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  @include panel-gradient($black-color);
  position: relative;
  overflow: hidden;

  .header{
    .title{
      color: $white-color;
    }
    .category{
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a{
        color: $white-color;
      }
    }
  }
}

.panel-header-sm{
  height: 135px;
}

.panel-header-lg{
  height: 380px
}
